<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container mt-5">
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center justify-content-center">               
        <div class="col-12">
          <img src="@/assets/img/404.png" width="400" alt="">
          <div align="center">
            <h1 class="h1-404">404 Not Found</h1>
            <h2>Page not found</h2>
            <p>
              We locked everywhere for this page. <br> 
              Are you sure the website URL is correct?<br>
              Get in touch with the site owner
            </p>
            <div class="col-md-3">
              <a class=" button-1 w-100 btn btn-xs btn-primary"  @click="$router.go(-2)"> Go Back</a>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default
  {
    name: '404' 
  }
</script>

<style></style>
