<template>   
   <HeaderDash />
   <SideMenu /> 
   <Content404 /> 
</template>

<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/worker/dashboard/HeaderDash.vue'
import SideMenu from '@/components/worker/dashboard/SideMenu'
import Content404 from '@/components/worker/dashboard/modules/404/Content404'
export default 
{
  name: '404',
  components: 
  {
    HeaderDash,
    SideMenu,
    Content404
  }
}
</script>

<style>


</style>
